import React from "react";
import { DropdownSelectSearchOption } from "../../Models/DropDownOptions";
import { DropdownState } from "./DropdownSelectSearchReducer/DropdownSelectSearchReducer";

export const TEST_IDS = {
    dropdown_select_search_options: "dropdown_select_search_options"
}

export interface DropdownSelectSearchOptionsProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, DropdownState {
    addNewFunction?: (search: string) => void;
    inputRef: HTMLInputElement | null;
    scrollContainer: Element | Document | null;
    closeOnSelect: boolean;
    multiple: boolean;
    onOptionChange: (selectedOptions:DropdownSelectSearchOption[]) => void;
}

export default function DropdownSelectSearchOptions(props:DropdownSelectSearchOptionsProps) {

    const {
        className = "select-search"
    } = props;



    return (
        <div
            className={`${className}__select ${props.inBounds?"":"hidden"}`}
            style={{
                top: (!props.isAbove?props.offset:undefined),
                bottom: (props.isAbove?props.offset:undefined),
                width: (props.inputRef?props.inputRef.clientWidth:undefined),
                maxHeight: props.maxHeight,
            }}
            data-testid={TEST_IDS.dropdown_select_search_options}
        >
            <ul
                className={`${className}__options`}
            >
                {props.filteredOptions.length ? 
                    props.filteredOptions.map((option) => (
                        <li
                            key={option.value}
                            className={`${className}__row`}
                        >
                            <button
                                value={option.convertedValue}
                                className={`${className}__option ${option.isSelected?"is-selected":""}`}
                                onMouseDown={(event) => {
                                    event.preventDefault(); // This is here to keep the input from loosing focus
                                }}
                                onClick={(event) => {
                                    event.preventDefault(); // This prevents the form's submission
                                    let optionsToReturn = [...props.selectedOptions];
                                    if (option.isSelected) {
                                        optionsToReturn = optionsToReturn.filter((opt) => {
                                            return opt.value !== option.value;
                                        })
                                    } else {
                                        const newOption = {...option, isSelected:!option.isSelected};
                                        if (!props.multiple) {
                                            optionsToReturn = [newOption];
                                        } else {
                                            optionsToReturn.push(newOption);
                                        }
                                    }
                                    
                                    props.onOptionChange(optionsToReturn);
                                    
                                    if (props.inputRef && props.closeOnSelect) {
                                        props.inputRef.blur();
                                    }
                                }}
                            >
                                {option.name}
                            </button>
                        </li>
                    ))
                    : (props.addNewFunction ?
                        <li
                            className={`${className}__row`}
                        >
                            <button
                                className={`${className}__option`}
                                onMouseDown={() => {
                                    props.addNewFunction!(props.searchValue);
                                }}
                            >
                            Add New
                            </button>
                        </li>
                        :
                        null
                    )
                }
            </ul>
        </div>
    );
}
