import { Group } from "../Groups";
import User, { UserFields, UserResponse } from "./User";


export class UserSummary extends User {
    is_active: boolean;
    phone: string;
    groups: Group[];
    constructor(response: UserSummaryResponse) {
        super(response);
        this.is_active = response.is_active;
        this.phone = response.phone;
        this.groups = response.groups;
    }
    getGroup() {
        return this.groups.length > 0 ? this.groups[0] : null;
    }
    getGroupName() {
        const group = this.getGroup();
        return group ? group.name : "None";
    }
}

export interface UserSummaryResponse extends UserResponse {
    is_active: boolean;
    phone: string;
    groups: Group[];
}

enum UserSummaryFieldsEnum {
    is_active = "is_active",
    phone = "phone",
    groups = "groups",
}

export const UserSummaryFields = {
    ...UserFields,
    ...UserSummaryFieldsEnum,
};
