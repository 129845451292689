import React from "react";
import { PageOptions } from "../constants";
import AuthProvider from "./AuthProvider";
import PageProvider from "./PageProvider";
import UserOutageProvider from "./UserOutageProvider";


type AppProviderProps = {
    children: React.ReactNode;
    page: PageOptions
}

export default function AppProvider(props:AppProviderProps) {
    const postHomeClass = (props.page !== PageOptions.home)?" after_home":"";
    return (
        <React.StrictMode>
            <AuthProvider>
                <PageProvider page={props.page}>
                    <UserOutageProvider>
                        <div className={postHomeClass}>
                            {props.children}
                        </div>
                    </UserOutageProvider>
                </PageProvider>
            </AuthProvider>
        </React.StrictMode>
    );
}
