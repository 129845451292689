import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Footer from "./Components/Footer";
import "./Main.scss";
import "./MainText.scss";
import "react-datepicker/dist/react-datepicker.css";

type MainRouteProps = {
    header: React.ReactNode;
    body: React.ReactNode;
}

export default function Main(props:MainRouteProps) {
    return (
        <>
            <div className="app_no_footer">
                <Router>
                    {props.header}
                    <div className="main_body">
                        {props.body}
                    </div>
                </Router>
            </div>
            <Footer />
        </>
    );
}