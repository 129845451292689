
function constructObject<T extends Object>(obj:T) {
    try {
        // @ts-ignore
        const newObject:T = new obj.constructor();
        return newObject;
    } catch (e) {
        return null;
    }
}

export function copyObj<T extends Object>(obj:T) {
    let objCopy = constructObject(obj);
    if (!objCopy) {
        objCopy = Object.create(Object.getPrototypeOf(obj)) as T;
    }
    if (obj instanceof Date) {
        objCopy = (new Date(obj.getTime())) as any as T;
    }
    for (const key of (Reflect.ownKeys(obj) as (keyof T)[])) {
        const valueToCopy = obj[key];
        if (typeof valueToCopy === "function") {
            if (!objCopy[key]) {
                objCopy[key] = valueToCopy;
                // @ts-ignore
                objCopy[key].bind(objCopy);
            }
        } else if (valueToCopy && typeof valueToCopy === "object") {
            objCopy[key] = copyObj(valueToCopy);
        } else {
            objCopy[key] = valueToCopy;
        }
    }
    return objCopy;
}

type CopyAndSetKeyOptions<T, k extends keyof T> = {
    obj:T;
    keyValues: {
        key: k;
        value: T[k];
    }[],
    afterCopyAction?: (newObj:T, currKey:k) => void;
}

// TODO - This should probably be refactored to take an array of objects (key and value)
// TODO - This will help ensure keys and values are matched and reduce the potential for bugs.
export function copyAndSetKey<T extends Object, K extends keyof T>(options:CopyAndSetKeyOptions<T, K>) {
    const newObj = copyObj(options.obj);
    options.keyValues.forEach((keyValue) => {
        newObj[keyValue.key] = keyValue.value;
        if (options.afterCopyAction) {
            options.afterCopyAction(newObj, keyValue.key);
        }
    });
    return newObj;
}