
export const static_url = "/static/";
export const media_url = "/media/";

export const CSRF_TOKEN = "csrftoken";
export const AUTO_SCROLL_INCREMENT = 15;

export const routes = {
    calendar: "/auth/calendar/",
    company: "/company/index/",
    contact: "/contact-us/",
    contractor: "/contractor/index/",
    home: "/",
    login: "/account/login/",
    outage: "/outage/index/",
    profile: "/users/index/#profile",
    // user: "/auth/users/",
    user: "/users/index/",
    violation: "/violation/all/",
    burnPermits: "/auth/permits/burn_permit/",
    confinedSpacePermits: "/auth/permits/confined_space/",
    incidents: "/auth/incidents/",
    workLog: "/auth/worklog/",
} as const;


export const LocalStorageIds = {
    currentOutage:"selected_outage_id",
} as const;

export enum PageOptions {
    home = "home",
    login = "login",
    auth = "auth",
    none="none"
}

export const loaderSize = {
    large: "large",
    small: "small_loader"
} as const;

export const IMAGE_HEIGHT = 80;

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
] as const;

export enum VIOLATION_TYPES {
    None,
    LS,
    FP,
    EP,
    OSHA,
}

export enum permissions {
    add_burnpermit = "add_burnpermit",
    add_burnpermitshift = "add_burnpermitshift",
    add_company = "add_company",
    add_confinedspacepermit = "add_confinedspacepermit",
    add_confinedspacepermitshift = "add_confinedspacepermitshift",
    add_contact = "add_contact",
    add_contenttype = "add_contenttype",
    add_contractor = "add_contractor",
    add_group = "add_group",
    add_incident = "add_incident",
    add_incidentimages = "add_incidentimages",
    add_incidentlog = "add_incidentlog",
    add_incidentstatus = "add_incidentstatus",
    add_logentry = "add_logentry",
    add_outage = "add_outage",
    add_permission = "add_permission",
    add_permit = "add_permit",
    add_permitcontact = "add_permitcontact",
    add_permitlocation = "add_permitlocation",
    add_session = "add_session",
    add_shift = "add_shift",
    add_subcontractor = "add_subcontractor",
    add_typeofchange = "add_typeofchange",
    add_user = "add_user",
    add_usercontractor = "add_usercontractor",
    add_useroutage = "add_useroutage",
    add_userwithcompany = "add_userwithcompany",
    add_violation = "add_violation",
    add_violationset = "add_violationset",
    add_violationtype = "add_violationtype",
    add_worklog = "add_worklog",
    add_worklognotes = "add_worklognotes",
    change_burnpermit = "change_burnpermit",
    change_burnpermitshift = "change_burnpermitshift",
    change_company = "change_company",
    change_confinedspacepermit = "change_confinedspacepermit",
    change_confinedspacepermitshift = "change_confinedspacepermitshift",
    change_contact = "change_contact",
    change_contenttype = "change_contenttype",
    change_contractor = "change_contractor",
    change_group = "change_group",
    change_incident = "change_incident",
    change_incidentimages = "change_incidentimages",
    change_incidentlog = "change_incidentlog",
    change_incidentstatus = "change_incidentstatus",
    change_logentry = "change_logentry",
    change_outage = "change_outage",
    change_permission = "change_permission",
    change_permit = "change_permit",
    change_permitcontact = "change_permitcontact",
    change_permitlocation = "change_permitlocation",
    change_session = "change_session",
    change_shift = "change_shift",
    change_shiftuser = "change_shiftuser",
    change_subcontractor = "change_subcontractor",
    change_typeofchange = "change_typeofchange",
    change_user = "change_user",
    change_usercontractor = "change_usercontractor",
    change_usergroup = "change_usergroup",
    change_useroutage = "change_useroutage",
    change_userwithcompany = "change_userwithcompany",
    change_violation = "change_violation",
    change_violationset = "change_violationset",
    change_violationtype = "change_violationtype",
    change_worklog = "change_worklog",
    change_worklognotes = "change_worklognotes",
    delete_burnpermit = "delete_burnpermit",
    delete_burnpermitshift = "delete_burnpermitshift",
    delete_company = "delete_company",
    delete_confinedspacepermit = "delete_confinedspacepermit",
    delete_confinedspacepermitshift = "delete_confinedspacepermitshift",
    delete_contact = "delete_contact",
    delete_contenttype = "delete_contenttype",
    delete_contractor = "delete_contractor",
    delete_group = "delete_group",
    delete_incident = "delete_incident",
    delete_incidentimages = "delete_incidentimages",
    delete_incidentlog = "delete_incidentlog",
    delete_incidentstatus = "delete_incidentstatus",
    delete_logentry = "delete_logentry",
    delete_outage = "delete_outage",
    delete_permission = "delete_permission",
    delete_permit = "delete_permit",
    delete_permitcontact = "delete_permitcontact",
    delete_permitlocation = "delete_permitlocation",
    delete_session = "delete_session",
    delete_shift = "delete_shift",
    delete_subcontractor = "delete_subcontractor",
    delete_typeofchange = "delete_typeofchange",
    delete_user = "delete_user",
    delete_usercontractor = "delete_usercontractor",
    delete_useroutage = "delete_useroutage",
    delete_userwithcompany = "delete_userwithcompany",
    delete_violation = "delete_violation",
    delete_violationset = "delete_violationset",
    delete_violationtype = "delete_violationtype",
    delete_worklog = "delete_worklog",
    delete_worklognotes = "delete_worklognotes",
    view_burnpermit = "view_burnpermit",
    view_burnpermitshift = "view_burnpermitshift",
    view_company = "view_company",
    view_confinedspacepermit = "view_confinedspacepermit",
    view_confinedspacepermitshift = "view_confinedspacepermitshift",
    view_contact = "view_contact",
    view_contenttype = "view_contenttype",
    view_contractor = "view_contractor",
    view_group = "view_group",
    view_incident = "view_incident",
    view_incidentimages = "view_incidentimages",
    view_incidentlog = "view_incidentlog",
    view_incidentstatus = "view_incidentstatus",
    view_logentry = "view_logentry",
    view_outage = "view_outage",
    view_permission = "view_permission",
    view_permit = "view_permit",
    view_permitcontact = "view_permitcontact",
    view_permitlocation = "view_permitlocation",
    view_session = "view_session",
    view_shift = "view_shift",
    view_subcontractor = "view_subcontractor",
    view_typeofchange = "view_typeofchange",
    view_user = "view_user",
    view_usercontractor = "view_usercontractor",
    view_useroutage = "view_useroutage",
    view_userwithcompany = "view_userwithcompany",
    view_violation = "view_violation",
    view_violationset = "view_violationset",
    view_violationtype = "view_violationtype",
    view_worklog = "view_worklog",
    view_worklognotes = "view_worklognotes",
};
