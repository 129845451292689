import React from "react";
import {HandleMenuClick} from "./Menu/MenuFunctions";
import "./HamburgerIcon.scss";

const hamburger_line_class = "hamburger_line";

export default function HamburgerIcon() {
    
    return (
        <div className="hamburger_icon" onClick={HandleMenuClick}>
            <div className={hamburger_line_class + " bar1"}></div>
            <div className={hamburger_line_class + " bar2"}></div>
            <div className={hamburger_line_class + " bar3"}></div>
        </div>
    );
}
