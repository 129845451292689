import React from "react";
import "./Footer.scss";

export default function Footer() {
    const d = new Date();
    return (
        <div className="text_white footer">
            &copy;{d.getFullYear()} Smokin' Fire Productions
        </div>
    );
}