import React, { createContext, useEffect, useState } from "react";
import LoadingDiv from "../Components/LoadingDiv";
import { loaderSize } from "../constants";
import { CurrentUser } from "../Models/Users/CurrentUser";
import UserAPI from "../Apis/User/UserAPI";

export interface AuthProviderContext {
    user?: CurrentUser
}
const AuthContext = createContext<AuthProviderContext>({} as AuthProviderContext);

type AuthProviderProps = {
    children: React.ReactNode;
}

export default function AuthProvider(props:AuthProviderProps) {
    const [user, setUser] = useState<CurrentUser|undefined>();
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    
    useEffect(() => {
        (async () => {
            try {
                const user = await UserAPI.getCurrentUser();
                setUser(user);
            } catch (err) {
                console.warn("ERROR:", err);
            }
        })();
        setIsCheckingAuth(false);
    }, []);

    if (isCheckingAuth) {
        return (
            <LoadingDiv
                loaderSize={loaderSize.large}
            />
        );
    } else {
        return (
            <AuthContext.Provider value={{user: user}}>
                {props.children}
            </AuthContext.Provider>
        );
    }
}

export function useGetAuthContext() {
    return React.useContext(AuthContext);
}
