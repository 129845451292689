import React, { createContext, useEffect, useMemo, useReducer } from "react";
import {useGetUserOutages} from "../Hooks/useGetUserOutages";
import Outage from "../Models/Outages/Outage";
import UserOutageReducer, { initialUserOutageReducerState, USER_OUTAGE_OPTIONS } from "../Reducers/UserOutageReducer";

export interface UserOutageProviderContext {
    currentOutage?: Outage;
    outages: Outage[];
    setCurrentOutage: (outageId:number | string) => void;
}
const UserOutageContext = createContext<UserOutageProviderContext>({} as UserOutageProviderContext);

type UserOutageProviderProps = {
    children:React.ReactNode;
}

export default function UserOutageProvider(props:UserOutageProviderProps) {
    const [state, dispatch] = useReducer(UserOutageReducer, initialUserOutageReducerState);
    const outages = useGetUserOutages();
    
    useEffect(() => {
        if (outages.length) {
            dispatch({type:USER_OUTAGE_OPTIONS.setOutages, outages:outages});
        }
    }, [outages]);

    const userOutageContext:UserOutageProviderContext = useMemo(() => {
        return {
            outages: outages,
            currentOutage: state.currentOutage,
            setCurrentOutage: (id) => {
                dispatch({type:USER_OUTAGE_OPTIONS.change, id:id});
            }
        };
    }, [dispatch, state, outages]);

    return (
        <UserOutageContext.Provider value={userOutageContext}>
            {props.children}
        </UserOutageContext.Provider>
    );
}

export function useGetUserOutageContext() {
    return React.useContext(UserOutageContext);
}
