
export interface FormValidationField {
    [key: string]: string;
}

export interface FormValidation {
    [key: string]: FormValidationField[]
}

export interface ServerSuccessResponse<T> {
    success: boolean,
    message: string,
    validation: FormValidation | null,
    data: T,
}

export default class SuccessResponse<T> {
    private _isLoading = true;
    private _isRedirecting = false;
    private _success = false;
    private _message = "";
    private _results:ServerSuccessResponse<T>| null = null;
    
    constructor(isLoading?:boolean) {
        if (isLoading !== undefined) {
            this._isLoading = isLoading;
        }
    }

    get isLoading() {
        return this._isLoading;
    }
    get isRedirecting() {
        return this._isRedirecting;
    }
    get success() {
        return this._success;
    }
    get message() {
        return this._message;
    }
    get results() {
        return this._results;
    }

    submit() {
        this._isLoading = true;
    }
    setResults(results:ServerSuccessResponse<T>) {
        this._isLoading = false;
        this._success = true;
        this._message = "";
        this._results = results;
    }
    setRedirect() {
        this._isLoading = false;
        this._isRedirecting = true;
        this._success = true;
        this._message = "";
        this._results = null;
    }
    setError(err:Error) {
        this.setMessage(err.message);
    }
    setMessage(message:string) {
        this._isLoading = false;
        this._success = false;
        this._message = message;
        this._results = null;
    }
}