import performFuzzySearch from "../../../Functions/FuzzySearch";
import { DropdownSelectSearchOption } from "../../../Models/DropDownOptions";
import DropdownAction, { DROPDOWN_ACTION_TYPES } from "./DropdownSelectSearchReducerActions";

export interface DropdownState {
    options: DropdownSelectSearchOption[];
    selectedOptions: DropdownSelectSearchOption[];
    filteredOptions: DropdownSelectSearchOption[];
    searchValue: string;
    isFocused: boolean;
    isAbove: boolean;
    offset: number;
    inBounds: boolean;
    maxHeight: number;
}

export default function DropdownSelectSearchReducer(state:DropdownState, action: DropdownAction) {
    switch (action.type) {
        case DROPDOWN_ACTION_TYPES.SET_OPTIONS: {
            const filteredResults = searchAction(action.options, state.searchValue);
            const selectedOptions = action.options.filter((option) => {
                return option.isSelected;
            });
            return {
                ...state,
                options: action.options,
                selectedOptions: selectedOptions,
                filteredOptions: filteredResults
            }
        }
        case DROPDOWN_ACTION_TYPES.SEARCH: {
            const filteredResults = searchAction(state.options, action.searchValue);
            return {
                ...state,
                searchValue: action.searchValue,
                filteredOptions: filteredResults
            }
        }
        case DROPDOWN_ACTION_TYPES.TOGGLE_FOCUS: {
            return {
                ...state,
                isFocused: action.isFocused,
                isAbove: action.isAbove,
                offset: action.offset,
                inBounds: action.inBounds,
                maxHeight: action.maxHeight,
                searchValue: "",
                filteredOptions: searchAction(state.options, ""),
            }
        }
        case DROPDOWN_ACTION_TYPES.SCROLL: {
            return {
                ...state,
                isAbove: action.isAbove,
                offset: action.offset,
                inBounds: action.inBounds,
                maxHeight: action.maxHeight
            }
        }
        default: {
            return state;
        }
    }
}

function searchAction(options:DropdownSelectSearchOption[], searchValue:string) {
    const keys = ["name", "groupName"];
    return performFuzzySearch(options, searchValue, keys);
}
