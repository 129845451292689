import Outage from "../../Models/Outages/Outage";
import { BurnPermitShift } from "../../Models/Permits/BurnPermit/BurnPermitShift";
import { ConfinedSpacePermitShift } from "../../Models/Permits/ConfinedSpacePermit/ConfinedSpacePermitShift";
import { ObjectWithId } from "../../Models/_ObjectWithId";
import { Route, RouteWithOutage, objectToString, objectWithNullableIDToString } from "../AbstractRoutes";

export class PermitRoutes extends Route {
    burn_permits: PermitRoute;
    confinedspace_permits: PermitRoute;
    locations:string;
    contacts: string;
    constructor(main_uri: string) {
        super(main_uri);
        this.burn_permits = new PermitRoute(`${main_uri}burn_permits/`);
        this.confinedspace_permits = new PermitRoute(`${main_uri}confinedspace_permits/`);
        this.locations = `${main_uri}locations/`;
        this.contacts = `${main_uri}contacts/`
    }

    related_fields = (outage: Outage | undefined) => {
        const outageId = objectToString(outage);
        return `${this.main_uri}outage/${outageId}related_fields/`;
    };
}
class PermitRoute extends RouteWithOutage {
    shifts: string;
    constructor(main_uri: string) {
        super(main_uri);
        this.shifts = `${this.main_uri}shifts/`
    }
}
