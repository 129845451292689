import { API_ROUTES } from "../ApiRoutes/ApiRoutes";
import Outage, { OutageResponse } from "../Models/Outages/Outage";

export default class OutageAPI {
    static async getUserOutages() {
        const response = await fetch(API_ROUTES.user_outages);
        const outageResult = await response.json() as OutageResponse[];
        const outageList = outageResult.map((responseOutage) => {
            return new Outage(responseOutage);
        });
        return outageList;
    }
}
