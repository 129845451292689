import React from "react";
import { PageOptions, routes } from "../../../../constants";
import { useGetAuthContext } from "../../../../Providers/AuthProvider";
import { PermissionLink } from "../../Links";
import {HandleMenuClick} from "../MenuFunctions";


export function PermitDropdown() {
    const {user} = useGetAuthContext();

    return (
        <>
            {user && user.permissions.view_permit?
                <div className="nav_item dropdown sub_dropdown closed">
                    <div onClick={HandleMenuClick} className="text_white main_text">Permits</div>
                    <div className="collapsible_menu sub_collapsible_menu">
                        <PermissionLink
                            has_permission={user?.permissions.view_burnpermit}
                            page={PageOptions.auth}
                            to={routes.burnPermits}
                            desc="Burn Permits"
                        />
                        <PermissionLink
                            has_permission={user?.permissions.view_confinedspacepermit}
                            page={PageOptions.auth}
                            to={routes.confinedSpacePermits}
                            desc="Confined Space"
                        />
                    </div>
                </div>
                :
                null
            }
        </>
    );
}
