import Outage from "../Models/Outages/Outage";
import { ObjectWithId } from "../Models/_ObjectWithId";

interface objectWithId {
    id:number;
}

export function objectToString<T extends objectWithId>(object: T | undefined) {
    return object ? (`${object.id}/`) : "";
}
export function objectWithNullableIDToString<T extends ObjectWithId>(object: T | undefined) {
    let returnValue = "";
    if (object && object.id) {
        returnValue =  `${object.id}/`;
    }
    return returnValue;
}
export abstract class Route {
    main_uri: string;
    constructor(main_uri: string) {
        this.main_uri = main_uri;
    }
}
export abstract class RouteWithOutage extends Route {
    constructor(main_uri: string) {
        super(main_uri);
    }
    outage = (outage: Outage | undefined) => {
        const outageId = objectToString(outage);
        return `${this.main_uri}outage/${outageId}`;
    };
}
