import React from "react";
import {PageOptions, routes} from "../../../constants";
import { useGetAuthContext } from "../../../Providers/AuthProvider";
import { PermissionLink } from "../Links";
import { AdminDropdown } from "./Dropdown/AdminDropdown";
import { PermitDropdown } from "./Dropdown/PermitDropdown";
import { ProfileDropdown } from "./Dropdown/ProfileDropdown";

export function AuthMenu() {
    const {user} = useGetAuthContext();

    return (
        <>
            <PermissionLink
                has_permission={user?.permissions.view_incident}
                page={PageOptions.auth}
                to={routes.incidents}
                desc="Incidents"
            />
            <PermissionLink
                has_permission={user?.permissions.view_shift}
                page={PageOptions.auth}
                to={routes.calendar}
                desc="Calendar"
            />
            <PermissionLink
                has_permission={user?.permissions.view_worklog}
                page={PageOptions.auth}
                to={routes.workLog}
                desc="Work Logs"
            />
            <PermitDropdown />
            <AdminDropdown />
            <ProfileDropdown />
        </>
    );
}

