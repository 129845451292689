import { DropdownSelectSearchOption } from "../../../Models/DropDownOptions";

export enum DROPDOWN_ACTION_TYPES {
    SEARCH = "search",
    SET_OPTIONS = "setOptions",
    TOGGLE_FOCUS = "toggleFocus",
    SCROLL = "aboveOrBelow",
}

export interface DropdownSetOptionsAction {
    type: DROPDOWN_ACTION_TYPES.SET_OPTIONS;
    options: DropdownSelectSearchOption[];
}

export interface DropdownSearchAction {
    type: DROPDOWN_ACTION_TYPES.SEARCH;
    searchValue: string;
}

export interface DropdownScrollAction {
    type: DROPDOWN_ACTION_TYPES.SCROLL;
    isAbove: boolean;
    offset: number;
    inBounds: boolean;
    maxHeight: number;
}

export interface DropdownFocusAction {
    type: DROPDOWN_ACTION_TYPES.TOGGLE_FOCUS;
    isFocused: boolean;
    isAbove: boolean;
    offset: number;
    inBounds: boolean;
    maxHeight: number;
}

type DropdownAction = DropdownSetOptionsAction | DropdownSearchAction | DropdownFocusAction | DropdownScrollAction

export default DropdownAction
