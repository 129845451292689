import { permissions } from "../../constants";
import { PartialRecord } from "../../types";
import User, { UserFields, UserResponse } from "./User";


export class CurrentUser extends User {
    permissions: PartialRecord<permissions, boolean> = {};

    constructor(response?:CurrentUserResponse) {
        super(response);
        if (response) {
            this.permissions = response.permissions;
        }
    }
}


export interface CurrentUserResponse extends UserResponse {
    permissions: PartialRecord<permissions, boolean>;
}

export enum CurrentUserFieldsEnum {
    permissions = "permissions",
}

export const UserSummaryFields = {
    ...UserFields,
    ...CurrentUserFieldsEnum,
};
