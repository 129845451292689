import React from "react";

interface ScrollListenerOptions {
    onScroll: () => void;
    scrollContainer: Element | Document | null;
}

export default function useScrollListener(options:ScrollListenerOptions) {
    const running = React.useRef(false);
    const oldElement = React.useRef<Element | Document>();
    const docScrollListener = React.useCallback((_:any) => {
        if (!running.current) {
            window.requestAnimationFrame(() => {
                options.onScroll();
                running.current = false;
            });
            running.current = true;
        }
    }, [options.onScroll]);

    
    React.useEffect(() => {
        if (options.scrollContainer && options.scrollContainer !== oldElement.current) {
            if (oldElement.current) {
                oldElement.current.removeEventListener("scroll", docScrollListener);
            }
            options.scrollContainer.addEventListener("scroll", docScrollListener);
            oldElement.current = options.scrollContainer;
        }
        return () => {
            if (oldElement.current) {
                oldElement.current.removeEventListener("scroll", docScrollListener);
            }
        };
    }, [options.scrollContainer]);

    React.useEffect(() => {
        window.addEventListener("resize", docScrollListener);
        return () => {
            window.removeEventListener("resize", docScrollListener);
        };
    }, []);
}
