import React from "react";
import { routes } from "../../../../constants";
import { API_ROUTES } from "../../../../ApiRoutes/ApiRoutes";
import { sendFetch } from "../../../../functions";
import SuccessResponse from "../../../../Models/Responses/SuccessResponse";
import { useGetAuthContext } from "../../../../Providers/AuthProvider";
import { ALink } from "../../Links";
import {HandleMenuClick} from "../MenuFunctions";


export function ProfileDropdown() {
    const {user} = useGetAuthContext();
    return (
        <div className="nav_item dropdown sub_dropdown closed">
            <div onClick={HandleMenuClick} className="text_white main_text">Welcome, {user?.first_name}</div>
            <div className="collapsible_menu sub_collapsible_menu">
                <ALink to={routes.profile} desc="Profile" />
                <ALink
                    to={API_ROUTES.logout}
                    desc="Logout"
                    onClick={(event)=> {
                        event.preventDefault();
                        sendFetch(
                            "GET",
                            API_ROUTES.logout,
                            new SuccessResponse()
                        );
                    }}
                />
            </div>
        </div>
    );
}
