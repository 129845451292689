import React from "react";
import { Link } from "react-router-dom";
import { PageOptions } from "../../constants";
import { useGetPageContext } from "../../Providers/PageProvider";

interface LinkProps {
    to: string;
    desc: string;
};
const link_classes = "nav_item nav_link text_white main_text";

interface PermissionLinkProps extends LinkProps {
    funcKey?: number;
    page: PageOptions;
    has_permission: boolean | undefined;
}

export function PermissionLink(props:PermissionLinkProps) {
    const {page} = useGetPageContext();
    let authLink: React.ReactElement | null = null;
    if (props.has_permission) {
        if (page === props.page) {
            authLink = <CustomLink key={props.funcKey} to={props.to} desc={props.desc} />;
        } else {
            authLink = <ALink key={props.funcKey} to={props.to} desc={props.desc} />;
        }
    }
    return authLink;
}

export function CustomLink(props: LinkProps) {
    return <Link className={link_classes} to={props.to}>{props.desc}</Link>;
}

interface ALinkProps extends LinkProps {
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function ALink(props: ALinkProps) {
    return <a className={link_classes} href={props.to} onClick={props.onClick}>{props.desc}</a>;
}
