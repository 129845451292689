import React, { useCallback, useMemo } from "react";
import useScrollListener from "../../Hooks/useScrollListener";
import { DropDownOption, DropdownSelectSearchOption } from "../../Models/DropDownOptions";
import { CloseButton } from "../Button/CloseButton";
import DropdownSelectSearchOptions from "./DropdownSelectSearchOptions";
import DropdownSelectSearchReducer, { DropdownState } from "./DropdownSelectSearchReducer/DropdownSelectSearchReducer";
import { DROPDOWN_ACTION_TYPES } from "./DropdownSelectSearchReducer/DropdownSelectSearchReducerActions";
import "./dropdown/default_dropdown.scss";
import "./dropdown/dropdown.scss";
import "./dropdown/form_dropdown.scss";

export const TEST_IDS = {
    dropdown_select_input: "dropdown_select_input"
}

export interface DropdownSelectSearchProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    options: DropDownOption[];
    values: (string | number | null)[] | number | null;
    scrollContainer: Element | Document | null;
    closeOnSelect?:boolean;
    multiple?:boolean;
    noRelative?: boolean;
    addNewFunction?: (search: string) => void;
    onOptionChange: (selectedOptions:DropdownSelectSearchOption[]) => void;
}

export default function DropdownSelectSearch (props:DropdownSelectSearchProps) {
    const mainDiv = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const {
        options,
        className = "select-search",
        closeOnSelect = true,
        placeholder = "Please Choose an Option",
        multiple = false,
        noRelative = false,
    } = props;

    const values = useMemo(() => {
        let standardizedValues:string[] = [];
        if (Array.isArray(props.values)) {
            props.values.forEach((value) => {
                standardizedValues.push(value ? value.toString() : "");
            });
        } else {
            standardizedValues.push(props.values ? props.values.toString() : "");
        }
        return standardizedValues;
    }, [props.values]);

    const mappedOptions = React.useMemo(() => {
        return mapOptionsToReducer(options, values)
    }, [options, values]);

    const [state, dispatch] = React.useReducer(DropdownSelectSearchReducer, {
        options: mappedOptions,
        selectedOptions: [] as any,
        filteredOptions: mappedOptions,
        searchValue:"",
        isFocused: false,
        isAbove: false,
        offset: 0,
        inBounds: true,
        maxHeight: 0,
    } as DropdownState);

    React.useEffect(() => {
        dispatch({
            type: DROPDOWN_ACTION_TYPES.SET_OPTIONS,
            options:mappedOptions
        });
    }, [mappedOptions]);

    const onScroll = useCallback(() => {
        const newIsAbove = calculateOptionsAboveOrBelow(mainDiv.current)
        dispatch({
            type: DROPDOWN_ACTION_TYPES.SCROLL,
            isAbove: newIsAbove,
            inBounds: calculateIsInBounds(props.scrollContainer, inputRef.current),
            maxHeight: calculateMaxHeight(inputRef.current, newIsAbove),
            offset: calculateOffset(inputRef.current, newIsAbove),
        });
    }, [props.scrollContainer]);

    useScrollListener({
        onScroll: onScroll,
        scrollContainer: props.scrollContainer
    });
    
    const dropdownOptions = React.useMemo(() => {
        let options:React.ReactElement | null = null;
        if (state.isFocused) {
            options = (
                <DropdownSelectSearchOptions
                    {...state}
                    className={className}
                    addNewFunction={props.addNewFunction}
                    inputRef={inputRef.current}
                    scrollContainer={props.scrollContainer}
                    closeOnSelect={closeOnSelect}
                    multiple={multiple}
                    onOptionChange={props.onOptionChange}
                />
            );
        }
        return options;
    }, [state, className, props.addNewFunction, closeOnSelect, multiple]);

    const focusAction = useCallback((isFocused:boolean) => {
        const newIsAbove = calculateOptionsAboveOrBelow(mainDiv.current)
        dispatch({
            type: DROPDOWN_ACTION_TYPES.TOGGLE_FOCUS,
            isFocused:isFocused,
            isAbove: newIsAbove,
            inBounds: calculateIsInBounds(props.scrollContainer, inputRef.current),
            maxHeight: calculateMaxHeight(inputRef.current, newIsAbove),
            offset: calculateOffset(inputRef.current, newIsAbove),
        });
    }, []);

    const placeholderValue = useMemo(() => {
        let value = placeholder;
        if (state.selectedOptions.length) {
            value = state.selectedOptions.map((obj) => {
                return obj.name
            }).join(", ");
        }
        return value
    }, [placeholder, state.selectedOptions]);

    return (
        <div
            className={`${className} ${(noRelative?"":"position_relative")}`}
            ref={mainDiv}
        >
            {state.isAbove && dropdownOptions}
            <div className={`${className}__value`}>
                <input
                    data-testid={TEST_IDS.dropdown_select_input}
                    ref={inputRef}
                    placeholder={placeholderValue}
                    className={`${className}__input`}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    value={state.searchValue}
                    onChange={(event) => {
                        dispatch({
                            type: DROPDOWN_ACTION_TYPES.SEARCH,
                            searchValue: event.target.value
                        })
                    }}
                    onFocus={(event) => {
                        focusAction(true);
                    }}
                    onBlur={() => {
                        focusAction(false);
                    }}
                />
            </div>
            {!state.isAbove && dropdownOptions}
            {multiple ?
                <div className="selected_multiple_option_container">
                    {state.selectedOptions.map((option) => {
                        return (
                            <div className="selected_multiple_option noselect" key={option.value}>
                                <div className="selected_option">
                                    {option.name}
                                </div>
                                <button
                                    value={option.convertedValue}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        let optionsToReturn = [...state.selectedOptions];
                                        optionsToReturn = optionsToReturn.filter((opt) => {
                                            return opt.value !== option.value;
                                        })
                                        props.onOptionChange(optionsToReturn);
                                    }}
                                >
                                    <CloseButton
                                        clickEvent={() => {}}
                                    />
                                </button>
                            </div>
                        );
                    })}
                </div>
                :
                null
            }
        </div>
    );
}

function mapOptionsToReducer(options: DropDownOption[], values: string[]):DropdownSelectSearchOption[] {
    return options.map((option, index) => {
        const stringValue = option.value ? option.value.toString() : "";
        return {
            ...option,
            index:index,
            isSelected: values.includes(stringValue),
            convertedValue: stringValue,
        }

    })
}

function calculateOptionsAboveOrBelow(mainDiv: HTMLDivElement | null) {
    let isAbove = false
    if (mainDiv) {
        const viewBottom = window.innerHeight || document.documentElement.clientHeight;
        const bounding = mainDiv.getBoundingClientRect();
        const topDiff = bounding.top;
        const bottomDiff = viewBottom - bounding.bottom;

        if (topDiff > bottomDiff) {
            isAbove = true;
        }
    }
    return isAbove
}

function calculateIsInBounds(scrollContainer: Element | Document | null, inputRef: HTMLInputElement | null) {
    let isInbounds = true;
    if (scrollContainer && !(scrollContainer instanceof Document) && inputRef) {
        const scrollBinding = scrollContainer.getBoundingClientRect();
        const inputBinding = inputRef.getBoundingClientRect();
        if (scrollBinding.y > (inputBinding.y + inputRef.clientHeight)) {
            isInbounds = false;
        } else if((scrollBinding.y + scrollContainer.clientHeight) < inputBinding.y) {
            isInbounds = false;
        }
    }
    return isInbounds
}

function calculateMaxHeight(inputRef: HTMLInputElement | null, isAbove:boolean) {
    const edgeBuffer = 20;
    const finalMaxHeight = 360;
    let newHeight = 0;
    if (inputRef) {
        const inputBinding = inputRef.getBoundingClientRect();
        if (isAbove) {
            newHeight = (inputBinding.top - edgeBuffer < finalMaxHeight) ? (inputBinding.top - edgeBuffer) : finalMaxHeight;
        } else {
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
            newHeight = (windowHeight - inputBinding.bottom - edgeBuffer < finalMaxHeight) ? (windowHeight - inputBinding.bottom - edgeBuffer) : finalMaxHeight;
        }
    }
    return newHeight;
}

function calculateOffset(inputRef: HTMLInputElement | null, isAbove:boolean) {
    let offset = 0;
    if (inputRef) {
        const relParent = inputRef.closest(".position_relative");
        if (relParent) {
            const relBinding = relParent.getBoundingClientRect();
            const inputBinding = inputRef.getBoundingClientRect();
            if (isAbove) {
                offset = relBinding.bottom - inputBinding.top;
            } else {
                offset = inputBinding.bottom - relBinding.top;
            }
        }
    }
    return offset;
}
