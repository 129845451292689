
export default abstract class User {
    id = 0;
    first_name = "";
    last_name = "";
    email = "";
    is_admin = false;
    constructor(response?:UserResponse) {
        if (response) {
            this.id = response.id;
            this.first_name = response.first_name;
            this.last_name = response.last_name;
            this.email = response.email;
            this.is_admin = response.is_admin;
        }
    }
}

export interface UserResponse {
    id:number;
    first_name: string;
    last_name: string;
    email: string;
    is_admin: boolean;
}

export enum UserFields {
    id = "id",
    first_name = "first_name",
    last_name = "last_name",
    email = "email",
    is_admin = "is_admin",
}
