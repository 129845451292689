import { ViolationSetOrType } from "../Violation";

export default class Outage {
    id = 0;
    prefix = "";
    name = "";
    company = 0;
    violation_set: ViolationSetOrType | null = null;
    start_date: Date = new Date();
    end_date: Date = new Date();
    email_enabled = false;
    constructor(response?:OutageResponse) {
        if (response) {
            this.id = response.id;
            this.prefix = response.prefix;
            this.name = response.name;
            this.company = response.company;
            this.violation_set = response.violation_set;
            this.start_date = new Date(response.start_date);
            this.end_date = new Date(response.end_date);
            this.email_enabled = response.email_enabled;
        }
    }
}

export interface OutageResponse {
    id:number;
    prefix:string;
    name:string;
    company: number;
    violation_set: ViolationSetOrType | null;
    start_date: string;
    end_date: string;
    email_enabled:boolean;
}

export const OutageFields = {
    id:"id",
    prefix:"prefix",
    name:"name",
    company:"company",
    violation_set:"violation_set",
    start_date:"start_date",
    end_date:"end_date",
    email_enabled:"email_enabled",
} as const;
