import { LocalStorageIds } from "../constants";
import Outage from "../Models/Outages/Outage";

type UserOutageState = {
    currentOutage?: Outage;
    outages: Outage[];
}

export enum USER_OUTAGE_OPTIONS {
    change = "change",
    setOutages = "setOutages",
}

type ChangeCurrentOutage = {
    type: USER_OUTAGE_OPTIONS.change;
    id: number | string;
}

type SetOutages = {
    type: USER_OUTAGE_OPTIONS.setOutages;
    outages: Outage[];
}

type UserOutageAction = ChangeCurrentOutage | SetOutages;

export const initialUserOutageReducerState:UserOutageState = {
    outages: []
};

export default function UserOutageReducer(state:UserOutageState, action:UserOutageAction) {
    switch (action.type) {
        case USER_OUTAGE_OPTIONS.change: {
            const currOutage = saveAndReturnCurrentOutage(action, state);
            return {
                ...state,
                currentOutage: currOutage, 
            };
        }
        case USER_OUTAGE_OPTIONS.setOutages: {
            let existingOutage: Outage | undefined;
            const stringId = localStorage.getItem(LocalStorageIds.currentOutage);
            if (stringId && !(stringId === "undefined")) {
                const id = parseInt(stringId);
                existingOutage = action.outages.find((outage) => {
                    return outage.id === id;
                });
            }
            return {
                ...state,
                currentOutage: existingOutage,
                outages:action.outages
            };
        }
        default: {
            return state;
        }
    }
}

function saveAndReturnCurrentOutage(action: ChangeCurrentOutage, state: UserOutageState) {
    const id = parseInt(action.id.toString());
    const existingId = localStorage.getItem(LocalStorageIds.currentOutage);
    if (!existingId || existingId !== id.toString()) {
        localStorage.setItem(LocalStorageIds.currentOutage, id.toString());
    }
    const currOutage = state.outages.find((outage) => {
        return outage.id === id;
    });
    return currOutage;
}
