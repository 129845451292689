import React from "react";
import {PageOptions} from "../../constants";
import {useGetAuthContext} from "../../Providers/AuthProvider";
import {useGetPageContext} from "../../Providers/PageProvider";
import { AuthMenu } from "./Menu/AuthMenu";
import HamburgerIcon from "./HamburgerIcon";
import { HomeMenu } from "./Menu/HomeMenu";
import { LoginMenu } from "./Menu/LoginMenu";
import { HandleClickOffMenu, HandleMenuTransition } from "./Menu/MenuFunctions";
import {useGetUserOutageContext} from "../../Providers/UserOutageProvider";
import "./NavBar.scss";
import "./collapsible_menus/collapsible_menu.scss";
import "./collapsible_menus/main_collapsible_menu.scss";
import "./collapsible_menus/sub_collapsible_menu.scss";
import DropdownSelectSearch from "../Dropdown/DropdownSelectSearch";
import { DropDownOption } from "../../Models/DropDownOptions";

document.addEventListener("click", (event) => {HandleClickOffMenu(event);});

export default function NavBar() {
    let navMenu:JSX.Element;
    let outageSelect:JSX.Element | null = null;
    const {user} = useGetAuthContext();
    const {page} = useGetPageContext();
    const outageContext = useGetUserOutageContext();
    const options:DropDownOption[] = outageContext.outages.map(outage => {
        return {
            name: outage.name,
            value: outage.id,
        };
    });
    options.unshift({
        name: "",
        value: ""
    });

    if (user) {
        navMenu = <AuthMenu />;
        outageSelect = (
            <DropdownSelectSearch
                options={options}
                scrollContainer={document}
                placeholder="Please Choose An Outage"
                values={outageContext.currentOutage?outageContext.currentOutage.id:null}
                onOptionChange={(selectedOptions) => {
                    let outageId = "";
                    if (selectedOptions.length) {
                        const option = selectedOptions[0];
                        outageId = option.convertedValue;
                    }
                    outageContext.setCurrentOutage(outageId);
                }}
            />
            // <DropdownSearch
            //     options={options}
            //     scrollContainer={document}
            //     placeholder="Please Choose An Outage"
            //     emptyMessage="No Outages Found"
            //     value={outageContext.currentOutage?outageContext.currentOutage.id.toString():undefined}
            //     onChange={(value) => {
            //         const selected = value as any as number;
            //         outageContext.setCurrentOutage(selected);
            //     }}
            // />
        );
    } else if (page === PageOptions.login) {
        navMenu = <LoginMenu />;
    } else {
        navMenu = <HomeMenu />;
    }

    return (
        <div className="dropdown nav_bar">
            <HamburgerIcon />
            <div onTransitionEnd={HandleMenuTransition} className="collapsible_menu main_collapsible_menu">
                {navMenu}
            </div>
            {outageSelect}
        </div>
    );
}
