import React from "react";
import { PageOptions, routes } from "../../../../constants";
import { useGetAuthContext } from "../../../../Providers/AuthProvider";
import { PermissionLink } from "../../Links";
import {HandleMenuClick} from "../MenuFunctions";


export function AdminDropdown() {
    const {user} = useGetAuthContext();
    let linksToDisplay:JSX.Element[] = [];
    if (user) {
        const links = [
            PermissionLink({
                funcKey: 1,
                has_permission: user.permissions.view_company,
                page: PageOptions.none,
                to: routes.company,
                desc: "Companies"
            }),
            PermissionLink({
                funcKey: 2,
                has_permission: user.permissions.view_contractor,
                page: PageOptions.none,
                to: routes.contractor,
                desc: "Contractors"
            }),
            PermissionLink({
                funcKey: 3,
                has_permission: user.permissions.view_outage,
                page: PageOptions.none,
                to: routes.outage,
                desc: "Outages"
            }),
            PermissionLink({
                funcKey: 4,
                has_permission: user.permissions.view_user,
                page: PageOptions.none,
                to: routes.user,
                desc: "Users"
            }),
            PermissionLink({
                funcKey: 5,
                has_permission: user.permissions.view_violation,
                page: PageOptions.none,
                to: routes.violation,
                desc: "Possible Violations"
            })
        ];
        links.forEach((link) => {
            if (link !== null) {
                linksToDisplay.push(link);
            }
        });
    }
    return (
        <>
            {linksToDisplay.length?
                <div className="nav_item dropdown sub_dropdown closed">
                    <div onClick={HandleMenuClick} className="text_white main_text">Admin</div>
                    <div className="collapsible_menu sub_collapsible_menu">
                        {[...linksToDisplay]}
                    </div>
                </div>
                :
                null
            }
        </>
    );
}
