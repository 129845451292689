import React from "react";
import { routes } from "../../../constants";
import { ALink, CustomLink } from "../Links";

export function HomeMenu(): JSX.Element {
    return (
        <>
            <CustomLink to={routes.home} desc="Home" />
            <CustomLink to={routes.contact} desc="Contact Us" />
            <ALink to={routes.login} desc="Login" />
        </>
    );
}
