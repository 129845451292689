import { Shift } from "../../Models/OutageCalendar/Shift";
import { objectToString, RouteWithOutage } from "../AbstractRoutes";

export class OutageCalendarRoutes extends RouteWithOutage {
    constructor(main_uri: string) {
        super(main_uri);
    }
    shifts = (shift?: Shift | undefined) => {
        const shiftId = objectToString(shift);
        return `${this.main_uri}shifts/${shiftId}`
    };
    workers = (shift?: Shift | undefined) => {
        const shiftId = objectToString(shift);
        return `${this.main_uri}shifts/workers/${shiftId}`
    };
}
