import Fuse from "fuse.js";

export default function performFuzzySearch<T>(options: T[], value: string, keys:string[]) {
    let returnOptions = options;
    let curValue = value;
    while (curValue && returnOptions.length) {
        curValue = curValue.trim();
        const spacePosition = curValue.indexOf(" ");
        let preSpaceValue = curValue;
        if (spacePosition > 0) {
            preSpaceValue = curValue.substring(0, spacePosition);
            curValue = curValue.substring(spacePosition + 1, curValue.length);
        } else {
            curValue = "";
        }
        if (preSpaceValue.length) {
            const threshold = setSearchThreshold(preSpaceValue);
            const fuse = new Fuse(returnOptions, {
                keys: keys,
                threshold: threshold,
                ignoreLocation: true,
                shouldSort: true
            });
            const searchValues = createFuzzySearchValues(keys, preSpaceValue);
            const search = fuse.search({
                $or: searchValues
            });
            const searchResult = search.map((obj) => {
                return obj.item;
            });
            returnOptions = searchResult;
        }
    }
    return returnOptions;
}

function setSearchThreshold(preSpaceValue: string) {
    let threshold = 0.4;
    if (!isNaN(preSpaceValue as any)) {
        threshold = 0;
    }
    return threshold;
}

function createFuzzySearchValues(keys: string[], preSpaceValue: string) {
    const searchValues: Object[] = [];
    keys.forEach((key) => {
        const obj = {};
        (obj as any)[key] = preSpaceValue;
        searchValues.push(obj);
    });
    return searchValues;
}

