import { CSRF_TOKEN, routes } from "./constants";
import { copyObj } from "./Functions/CopyObjects";
import FormSuccessResponse from "./Models/Responses/FormSuccessResponse";
import SuccessResponse from "./Models/Responses/SuccessResponse";

export function getCookie(name:string) {
    let cookieValue = "";
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + "=")) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export async function fetchWithData(
    type: "PUT" | "POST" | "DELETE",
    url:string,
    formSuccessResponse:FormSuccessResponse,
    data?:Object,
){
    const dataToSubmit = {...data};
    const csrftoken = getCookie(CSRF_TOKEN);

    return await sendFetch(type, url, formSuccessResponse, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
        },
        body: dataToSubmit?JSON.stringify(dataToSubmit):undefined
    });
}

export async function sendFetch<J, T extends SuccessResponse<J>>(
    type: "GET" | "POST" | "PUT" | "DELETE",
    url:string,
    fetchSuccessResponse:T,
    fetchArgs?: RequestInit
) {
    const successResponse = copyObj(fetchSuccessResponse);
    try {
        const response = await fetch(url, {
            ...fetchArgs,
            method: type,
        });
        let origin = window.location.origin;
        if (origin.lastIndexOf("/") === (origin.length - 1)) {
            origin = origin.substring(0, origin.length - 1);
        }
        if (response.redirected && response.url.substring(origin.length) !== (url + "/")) {
            window.location.href = response.url;
            successResponse.setRedirect();
        } else if (response.status === 401) {
            window.location.href = origin + routes.login;
        } else if (response.status >= 400) {
            successResponse.setMessage(`ERROR ${response.status}: ${response.statusText}`);
        } else {
            try {
                const result = await response.json();
                successResponse.setResults(result);
            } catch (error) {
                const err = error as Error;
                successResponse.setError(err);
            }
        }
    } catch (error) {
        const err = error as Error;
        successResponse.setError(err);
    }
    return successResponse;
}

export function getClassForHash(targetHash:string, classToAdd:string, allowBlank:boolean) {
    let classes = "";
    if (document.location.hash === targetHash || (allowBlank && document.location.hash === "")) {
        classes = classToAdd;
    }
    return classes;
}

export function getClassIfNotHash(targetHash:string, classToAdd:string, allowBlank:boolean) {
    let classes = "";
    if (document.location.hash !== targetHash && !allowBlank) {
        classes = classToAdd;
    } else if (document.location.hash !== targetHash && allowBlank && document.location.hash) {
        classes = classToAdd;
    }
    return classes;
}

type SetDateFieldOptions<T, k extends keyof T> = {
    obj:T,
    key:k,
    value:Date,
}

export function setDateFieldsForKey<T, k extends keyof T>(options:SetDateFieldOptions<T,k>) {
    const date = new Date(options.obj[options.key] as any as Date);
    date.setFullYear(options.value.getFullYear());
    date.setMonth(options.value.getMonth());
    date.setDate(options.value.getDate());
    return date;
}

type SetTimeFieldOptions<T, k extends keyof T> = {
    obj:T,
    key: k,
    hours:number,
    minutes:number,
}

export function setTimeFieldsForKey<T, k extends keyof T>(options:SetTimeFieldOptions<T,k>) {
    const date = new Date(options.obj[options.key] as any as Date);
    date.setHours(options.hours);
    date.setMinutes(options.minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}
