import { Route } from "./AbstractRoutes";
import { PermitRoutes } from "./SubRoutes/PermitRoutes";
import { IncidentRoutes } from "./SubRoutes/IncidentRoutes";
import { WorkLogRoutes } from "./SubRoutes/WorkLogRoutes";
import { OutageCalendarRoutes } from "./SubRoutes/OutageCalendarRoutes";

class ApiRoutes extends Route {
    current_user:string;
    incidents:IncidentRoutes;
    login:string;
    logout:string;
    outage_calendar:OutageCalendarRoutes;
    permits:PermitRoutes;
    user_outages:string;
    users:string;
    violations:string;
    workLogs:WorkLogRoutes;
    constructor(main_uri:string) {
        super(main_uri);
        this.current_user = `/user/current/`;
        this.incidents = new IncidentRoutes(`${main_uri}incidents/`);
        this.login = `${main_uri}account/login/`;
        this.logout = `${main_uri}account/logout/`;
        this.outage_calendar = new OutageCalendarRoutes(`${main_uri}outage_calendar/`);
        this.permits = new PermitRoutes(`${main_uri}permits/`);
        this.user_outages = `${main_uri}outages/for_user/`;
        this.users = `${main_uri}users/`;
        this.violations = `${main_uri}violations/`;
        this.workLogs = new WorkLogRoutes(`${main_uri}worklogs/`);
    }
}

export const API_ROUTES = new ApiRoutes("/api/");