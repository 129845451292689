import { useState, useEffect } from "react";
import OutageAPI from "../Apis/OutageAPI";
import Outage from "../Models/Outages/Outage";

export function useGetUserOutages() {
    const [outages, setOutages] = useState<Outage[]>([]);

    useEffect(() => {
        (async () => {
            const outageList = await OutageAPI.getUserOutages();
            setOutages(outageList);
        })();
    }, []);
    return outages;
}