import React, { createContext } from "react";
import { PageOptions } from "../constants";

export interface PageProviderContext {
    page?: PageOptions
}
const PageContext = createContext<PageProviderContext>({} as PageProviderContext);

type PageProviderProps = {
    children: React.ReactNode;
    page: PageOptions
}

export default function PageProvider(props:PageProviderProps) {
    return (
        <PageContext.Provider value={{page: props.page}}>
            {props.children}
        </PageContext.Provider>
    );
}

export function useGetPageContext() {
    return React.useContext(PageContext);
}
