import React from "react";

export function HandleMenuClick(e:React.MouseEvent) {
    const button = e.target as HTMLElement;
    const dropdown = button.closest(".dropdown");
    if (dropdown && !dropdown.classList.contains("open")) {
        dropdown.classList.add("open");
    } else if (dropdown) {
        const menu = dropdown.querySelector(".collapsible_menu");
        if (menu) {
            menu.classList.remove("finished_open");
        }
        dropdown.classList.remove("open");
    }
}

export function HandleMenuTransition(event:React.TransitionEvent) {
    const target = event.target as HTMLElement;
    const dropdown = target.closest(".dropdown");
    if (dropdown && dropdown.classList.contains("open")) {
        if (!target.classList.contains("finished_open")) {
            target.classList.add("finished_open");
        }
        if (target.classList.contains("sub_collapsible_menu")) {
            const menu = target.closest(".sub_dropdown");
            if (menu && menu.classList.contains("closed")) {
                menu.classList.remove("closed");
            }
        }
    } else {
        if (target.classList.contains("finished_open")) {
            target.classList.remove("finished_open");
        }
        if (target.classList.contains("sub_collapsible_menu")) {
            const menu = target.closest(".sub_dropdown");
            if (menu && !menu.classList.contains("closed")) {
                menu.classList.add("closed");
            }
        }
    }
}

export function HandleClickOffMenu(event:MouseEvent) {
    const target = event.target as HTMLElement;
    const openParent = target.closest(".open");
    if (!openParent || target.classList.contains("nav_link")) {
        const divsToHide = document.getElementsByClassName("open");
        closeMenus(divsToHide);
    } else if (openParent) {
        const parents = getParentMenus(openParent);
        const openMenusCollection = document.getElementsByClassName("open");
        const openMenus = [...openMenusCollection];
        const menusToHide = openMenus.filter((menu) => {
            let shouldClose = true;
            for (let i = 0; i < parents.length; i++) {
                if(parents[i].isSameNode(menu)) {
                    shouldClose = false;
                    break;
                }
            }
            return shouldClose;
        });
        closeMenus(menusToHide);
    }
}

function getParentMenus(openElement: Element) {
    const subMenus:Element[] = [];
    let currSelector:Element | null = openElement;
    while (currSelector) {
        subMenus.push(currSelector);
        currSelector = currSelector!.parentElement;
        if (currSelector) {
            currSelector = currSelector.closest(".open");
        }
    }
    return subMenus;
}

function closeMenus(menusToClose:Element[] | HTMLCollectionOf<Element>) {
    for (let i = menusToClose.length - 1; i >= 0; i--) {
        const menu = menusToClose[i].querySelector(".collapsible_menu");
        if (menu) {
            menu.classList.remove("finished_open");
        }
        menusToClose[i].classList.remove("open");
    }
}