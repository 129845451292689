import Outage from "../../Models/Outages/Outage";
import { RouteWithOutage } from "../AbstractRoutes";

export class IncidentRoutes extends RouteWithOutage {
    pdfReport: string;

    constructor(main_uri: string) {
        super(main_uri);
        this.pdfReport = `${this.main_uri}pdf_report/`;
    }

    related_fields = (outage: Outage | undefined) => {
        const outageUri = this.outage(outage);
        return `${outageUri}related_fields/`;
    };
    images = (imgId?: number) => {
        const extension = imgId ? `${imgId}/` : "";

        return `${this.main_uri}images/${extension}`;
    };
}
