import { UserSummary, UserSummaryResponse } from "../../Models/Users/UserSummary";
import { CurrentUser, CurrentUserResponse } from "../../Models/Users/CurrentUser";
import { API_ROUTES } from "../../ApiRoutes/ApiRoutes";

export default class UserAPI {
    static async getUsers() {
        const response = await fetch(API_ROUTES.users);
        const userResult = await response.json() as UserSummaryResponse[];
        const userList = userResult.map((responseUser) => {
            return new UserSummary(responseUser);
        });
        return userList;
    }
    static async getCurrentUser():Promise<CurrentUser | undefined> {
        const response = await fetch(API_ROUTES.current_user);
        const responseUser = await response.json() as CurrentUserResponse | undefined;
        let user:CurrentUser | undefined;
        if (responseUser) {
            user = new CurrentUser(responseUser);
        }
        return user;
    }
}
