import React from "react";
import {loaderSize} from "../constants";
import "./LoadingDiv.scss";

type LoaderProps = {
    loaderSize: string;
    style?: React.CSSProperties;
}

export default function LoadingDiv(props:LoaderProps) {
    const extraClass = props.loaderSize===loaderSize.small?"small_loader":"";
    return (
        <div
            className={"loader " + extraClass}
            style={props.style}
        >
        </div>
    );
}